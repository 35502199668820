<template>
  <!--        Search section start-->
  <form class="search-form">
    <div class="flex items-center justify-center">
      <div class="aselect" :data-value="searchSettingsObj.searchType" :data-list="searchOptions">
        <div class="selector min-w-[150px] flex items-center justify-between" id="dropdown" @click="visible=!visible">
          <div class="label">
            <span class="text-blue-dark text-sm">{{ searchSettingsObj.searchType }}</span>
          </div>
          <div class="arrow" :class="{ expanded : visible }"></div>
          <div :class="{ hidden : !visible, visible }">
            <ul>
              <li
                :class="{ current : item === searchSettingsObj.searchType }"
                class="text-sm font-normal"
                v-for="item in searchOptions"
                :key="item"
                @click="searchSettingsObj.searchType=item"
              >
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <input
        :class="`search-input w-[390px] bg-white appearance-none focus:outline-none placeholder-italic text-base leading-tight ml-0 md:ml-1 placeholder-gray-medium ${error ? 'text-red' : 'text-gray-dark'}`"
        type="text"
        @input="emptyError()"
        placeholder="Search the Geeq blockchain"
        v-model="searchSettingsObj.search"
      >
      <div class="flex items-center justify-between">
        <div class="dropdown bg-blue-light h-[46px] inline-flex items-center py-[7px] pl-[18px]"
        :class="{disabled: searchSettingsObj.searchType!=='Metadata'}">
          <img src="/images/icons/filter-icon.svg" alt="Filters" class="dropbtn mr-[15px]" />
          <span class="h-[30px] w-[2px] bg-white rounded-[2px]"></span>
          <div class="dropdown-content">
            <!--      Settings section start-->
            <div class="settings-box px-2.5 md:px-0">
              <template v-if="searchSettingsObj.searchType==='Metadata'">
                <p-text text="Settings" classes="text-blue-light text-sm jost-medium" />
                <div class="flex gap-20">
                  <p>
                    <input class="with-gap" value="meta" v-model="searchSettingsObj.searchStrType" name="encoding" type="radio" id="utf8">
                    <label for="utf8" class="text-blue-dark text-sm">Text (UTF-8)</label>
                  </p>
                  <!-- <p>
                    <input class="with-gap" value="meta64" v-model="searchSettingsObj.searchStrType" name="encoding" type="radio" id="base64">
                    <label for="base64" class="text-blue-dark text-sm">Binary</label>
                  </p> -->
                </div>
                <!-- <div class="pt-2.5" v-if="searchSettingsObj.searchStrType==='meta'">
                  <label class="checkcontainer text-blue-dark text-sm">Exact Match
                    <input type="checkbox" v-model="searchSettingsObj.isExactMatch">
                    <span class="checkmark"></span>
                  </label>
                </div> -->
              </template>
            </div>
            <!--      Settings section end-->
          </div>
        </div>
        <button v-on:keyup.prevent.enter="search" @click="search" class="bg-blue-light search-btn"></button>
      </div>
    </div>

  </form>
</template>

<script>
import "vue-json-pretty/lib/styles.css";
import PText from "@/components/common/PText";
import { searchOptions } from "@/utils/search";
import { mapGetters } from 'vuex';
import { maxTableRows } from "@/utils/pagination";

export default {
  name: "SearchForm",
  components: {
    PText,
  },
  props: {

  },
  data: () => {
    return {
      loading: false,
      initialLoad: true,
      searchSettingsObj: {
        searchType: 'Metadata',
        search: '',
        searchStrType: 'meta',
        isExactMatch: false,
      },
      searchOptions,
      error: "",
      visible: false,
      maxTableRows,
    }
  },
  computed: {
    ...mapGetters({
      searchData: 'getSearchData',
      searchSettings: 'getSearchSettings',
    }),
    isSearchEnabled() {
      return !!this.searchSettingsObj.search
    },
    blockNumbers() {
      let numArray = []
      if (this.searchSettingsObj.search && this.searchSettingsObj.searchType === "Block Number") {
        const term = this.searchSettingsObj.search;
        const regForNumber = new RegExp("^([1-9][0-9]*)$");
        const regNumsWithComma = new RegExp("^[1-9][0-9]*([,][1-9][0-9]*)*$")
        const regNumsWithRange = new RegExp("^([1-9][0-9]*)[-]([1-9][0-9]*)$")
        if (regForNumber.test(term)) {
          numArray.push(parseInt(this.searchSettingsObj.search))
        } else if (regNumsWithComma.test(term)) {
          numArray = this.searchSettingsObj.search.split(",")
          numArray = numArray.map(Number);
        } else if (regNumsWithRange.test(term)) {
          const rangeArr = term.split("-");
          for (let i = parseInt(rangeArr[0]); i <= parseInt(rangeArr[1]); i++) {
            numArray.push(i)
          }
        }
      }
      return numArray
    }
  },
  watch: {
    'searchSettingsObj.searchType': function () {
      if (!this.initialLoad) {
        if (this.searchSettingsObj.search) {
          this.searchSettingsObj.search = ""
        }
        if (this.searchSettingsObj.searchStrType !== 'meta') {
          this.searchSettingsObj.searchStrType = 'meta'
        }
        if (this.searchSettingsObj.isExactMatch) {
          this.searchSettingsObj.isExactMatch = false
        }
      }
      this.initialLoad = false
    },
    visible(dropOpen) {
      if (dropOpen) {
        document.addEventListener('click', this.closeIfClickedOutside);
      }
    }
  },
  mounted() {
    this.initialLoad = false
    this.searchSettingsObj = JSON.parse(JSON.stringify(this.searchSettings))
  },
  methods: {
    resetForm() {
      this.searchSettingsObj = {
        searchType: 'Metadata',
        search: '',
        searchStrType: 'meta',
        isExactMatch: false,
      }
    },
    validate() {
      const term = this.searchSettingsObj.search;
      // const re = new RegExp("^([1-9][0-9]*)$|^([1-9][0-9]*)[-]([1-9][0-9]*)$|^[1-9][0-9]*([,][1-9][0-9]*)*$");
      const re = new RegExp("^([1-9][0-9]*)$|^([1-9][0-9]*)$");

      if (re.test(term)) {
        // const expToTest = new RegExp("^([1-9][0-9]*)[-]([1-9][0-9]*)$");
        // const expToTest2 = new RegExp("^[1-9][0-9]*([,][1-9][0-9]*)*$");
        // if (expToTest.test(term)) {
        //   const rangeArr = term.split("-");
        //   if ((parseInt(rangeArr[1]) <= parseInt(rangeArr[0])) || (parseInt(rangeArr[1]) - parseInt(rangeArr[0]) >= 100)) {
        //     return false
        //   }
        // } else if (expToTest2.test(term) && this.blockNumbers.length >= 100) {
        //   return false
        // }
        return true
      }
      return false
    },

    search(e) {
      e.preventDefault();
      if (!this.isSearchEnabled) return;
      switch (this.searchSettingsObj.searchType) {
      case "Stack ID":
        this.searchSettingsObj.searchStrType = 'stackId'
        this.searchByStackId(this.searchSettingsObj.search);
        break;
      case "Block Number":
        this.searchSettingsObj.searchStrType = 'blockNumber'
        this.searchForBlockType();
        break;
      case "RefHash":
        this.searchSettingsObj.searchType = 'RefHash'
        this.searchSettingsObj.searchStrType = 'refHash'
        this.searchByRefhash('RefHash', "refHash", this.searchSettingsObj.search, maxTableRows, 0);
        break;
      case "AppCommitHash":
        this.searchSettingsObj.searchType = 'AppCommitHash'
        this.searchSettingsObj.searchStrType = 'appCommitHash'
          this.searchByHashOrMeta("AppCommitHash", 'hash', this.searchSettingsObj.search, maxTableRows, 0);
        break;
      case "Metadata":
        this.searchSettingsObj.searchType = 'Metadata';
          this.searchByHashOrMeta("Metadata", 'meta', this.searchSettingsObj.search, maxTableRows, 0);
        break;
      default: {
        let type = 'meta64'
        if (this.searchSettingsObj.searchStrType === 'meta') {
          this.searchSettingsObj.searchType = 'Metadata'
          // type = this.searchSettingsObj.isExactMatch ? 'metaExactMatch' : 'meta';
        }
        this.searchByHashOrMeta("Metadata", type, this.searchSettingsObj.search, maxTableRows, 0);
        break;
      }
      }
    },
    searchForBlockType() {
      if (!this.validate()) {
        this.error = "Input a valid search string";
        return false;
      } else {
        this.$store.commit('setSearchSettings', JSON.parse(JSON.stringify(this.searchSettingsObj)));

        this.searchByBlockNumbers();
      }
    },
    searchByStackId(stackId) {
      this.$router.push({ name: 'stacks', params: { stackId } });
      // this.loading = true;
      // const data = { stackId };

      this.$store.commit('setSearchSettings', JSON.parse(JSON.stringify(this.searchSettingsObj)));

      // this.$store.dispatch('searchByStackId', data)
      //   .catch(() => {
      //     this.$store.commit('resetSearchData');
      //     this.$toast.error('Something went wrong..', {
      //       position: 'bottom'
      //     });
      //   })
    },
    emptyError() {
      this.error = ""
    },
    closeIfClickedOutside(event) {
      if (!document.getElementById('dropdown').contains(event.target)) {
        this.visible = false;
        document.removeEventListener('click', this.closeIfClickedOutside);
      }
    },
    updateSearchParams(data) {
      this.$store.commit('setSearchSettings', JSON.parse(JSON.stringify(this.searchSettingsObj)))
      this.$store.commit('setSearchData', JSON.parse(JSON.stringify(data)))
    },
    searchByBlockNumbers() {
      this.loading = true;
      const data = { blockNumbers: this.blockNumbers };

      this.$store.dispatch('searchByBlockNumbers', data)
        .catch(() => {
          this.$store.commit('resetSearchData');
          this.$toast.error('Something went wrong..', {
            position: 'bottom'
          });
        })
    },
    searchByHashOrMeta(searchType, type, searchStr, limit, skip) {
      const data = { type, searchStr, limit, skip, searchSettings: {...this.searchSettingsObj} };

      const { search, searchStrType, isExactMatch } = data.searchSettings;
      this.$store.commit('setSearchSettings', data.searchSettings);
      this.$router.push({
        name: 'search-result',
        query: { type, search: search, exact: isExactMatch, refresh: new Date().getTime() }
      });

      // this.$store.dispatch('searchByHashOrMeta', data)
      //   .catch((error) => {
      //     this.updateSearchParams({})
      //     const msgOptions = { position: 'bottom' };

      //     if (error.response.status === 400) {
      //       this.$store.commit('resetSearchData')
      //       // this.$toast.error(error.response.data.error, msgOptions)
      //       return;
      //     }
      //     this.$store.commit('resetSearchData');
      //     // this.$toast.error('Something went wrong..', msgOptions)
      //   })
    },
    searchByRefhash(searchType, type, searchStr, limit, skip) {
      const data = { type, searchStr, limit, skip, searchSettings: {...this.searchSettingsObj} };
      this.$store.commit('setSearchSettings', this.searchSettingsObj);

      this.$router.push({
        name: 'transactions',
        query: { txRefhash: searchStr, refresh: new Date().getTime() }
      });
    },
  }
}
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-flex;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  overflow: hidden;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  max-height: 0;
  transition: max-height 0.2s ease-out;
  top: 46px;
  right: 0;
  border-radius: 8px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
  max-height: 200px; /* or whatever max height you want */
}
@media (max-width: 768px) {
  .search-input {
    max-width: 150px;
  }
}
</style>
