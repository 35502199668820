<template>
  <p class="text-left" :class="[classes]">
    {{ text }}
  </p>
</template>

<script>
export default {
  name: 'PText',
  props: {
    text: {
      required: true,
      type: String
    },
    classes: {
      required: false,
      type: String
    }
  }
}
</script>
