import { createStore } from 'vuex';
import api from '../helpers/api';
import router from '@/router';
import Vue from 'vue';

export interface State {
  searchSettings: {
    searchType: string;
    search: string | number;
    searchStrType: string;
    isExactMatch: boolean;
  };
  searchData: any;
  searchDataNft: any;
  pageCount: number;
  pageCountNft: number,
  isDataGotten: boolean;
  isLoadingInProgress: boolean;
}

export default createStore<State>({
  state: {
    searchSettings: {
      searchType: 'Metadata',
      search: '',
      searchStrType: 'meta',
      isExactMatch: false,
    },
    searchData: null,
    searchDataNft: null,
    pageCount: 0,
    pageCountNft: 0,
    isDataGotten: false,
    isLoadingInProgress: false
  },
  getters: {
    getSearchSettings: (state) => state.searchSettings,
    getIsDataGotten: (state) => state.isDataGotten,
    getSearchData: (state) => state.searchData,
    getSearchDataNft: (state) => state.searchDataNft,
    getPageCount: (state) => state.pageCount,
    getPageCountNft: (state) => state.pageCountNft,
    getIsLoadingInProgress: (state) => state.isLoadingInProgress,
  },
  mutations: {
    setSearchSettings(state, data) {
      state.searchSettings = data
    },
    setIsDataGotten(state, isGotten) {
      state.isDataGotten = isGotten
    },
    setIsLoadingInProgress(state, isLoading) {
      state.isLoadingInProgress = isLoading
    },
    setSearchData(state, data) {
      state.pageCount = data.meta ? data.meta.count : 1
      state.searchData = data;
    },
    setSearchDataNft(state, data) {
      state.pageCountNft = data.meta ? data.meta.count : 1;
      state.searchDataNft = data;
    },
    resetSearchData(state) {
      state.searchData = {};
    }
  },
  actions: {
    searchByStackId(state, data) {
      const { stackId } = data;

      router.push({ name: 'stacks', params: { stackId: stackId } });
    },
    searchByMetadata({state}, {type, searchStr, limit, skip, vueInstance}) {
      this.commit('setIsDataGotten', false);
      this.commit('setIsLoadingInProgress', true);

       return api.searchByMetadata(type, searchStr, limit, skip)
        .then((res) => {
          const data = res.data;

          this.commit('setIsDataGotten', true);
          data.next = { count: data.count };
          this.commit('setSearchData', data);
          this.commit('setIsLoadingInProgress', false);
        })
        .catch((err) => {
          const { error } = err.response.data;
          vueInstance.$toast.error(error);
          this.commit('setIsLoadingInProgress', false);
          this.commit('setSearchData', {});
        })
    },
    searchByRefhash(state, {searchStr, vueInstance}) {
      this.commit('setIsDataGotten', false);
      this.commit('setIsLoadingInProgress', true);

      return api.searchByRefhash(searchStr)
        .then((res) => {
          const data = res.data;

          this.commit('setIsDataGotten', true);
          this.commit('setSearchData', data);
          this.commit('setIsLoadingInProgress', false);
        })
        .catch((err) => {
          const { error } = err.response.data;
          vueInstance.$toast.error(error);
          this.commit('setIsLoadingInProgress', false);
          this.commit('setSearchData', {});
        })
    },
    searchByHashOrMeta({state, dispatch}, {type, searchStr, limit, skip, vueInstance}) {
      this.commit('setIsDataGotten', false);
      this.commit('setIsLoadingInProgress', true);
     if (type === 'meta' || type === 'metaExactMatch') {
        dispatch('searchByMetadata', {type, searchStr, limit, skip, vueInstance});
        return;
      }

      return api.searchByHashOrMeta(type, searchStr, limit, skip)
        .then((res) => {
          const data = res.data;

          this.commit('setIsDataGotten', true);
          this.commit('setSearchData', data);
          // this.commit('setSearchDataNft', hardcodedDataNft);
          this.commit('setIsLoadingInProgress', false);
        })
        .catch((err) => {
          const { error } = err.response.data;
          vueInstance.$toast.error(error);
          this.commit('setIsLoadingInProgress', false);
          this.commit('setSearchData', {});
        })
    },
    searchByBlockNumbers(state, data) {
      const { blockNumbers } = data;

      if (blockNumbers.length === 1) {
        this.commit('setSearchData', {});

        router.push({
          name: 'block',
          params: { number: blockNumbers[0] },
          query: { key: Math.random().toString() }
        });
        return;
      }

      this.commit('setIsDataGotten', false);
      this.commit('setIsLoadingInProgress', true);

      return api.searchByBlockNumbers(blockNumbers)
        .then((res) => {
          const data = res.data;
          this.commit('setIsDataGotten', true);
          this.commit('setSearchData', { ...data });
          // router.push({ name: 'blocks' });
          router.push({ name: 'search-result' });

          this.commit('setIsLoadingInProgress', false);

        })
        .catch(() => {
          this.commit('setIsLoadingInProgress', false);
        })
    }
  }
});
