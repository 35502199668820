<template>
  <div id="main" class="main_container my-container">
    <HeaderComponent />
    <main>
      <slot />
    </main>
    <div class="w-full">
      <FooterComponent />
    </div>
  </div>
</template>

<script>
import HeaderComponent from '../../components/HeaderComponent'
import FooterComponent from '@/components/FooterComponent';
export default {
  components: {
    HeaderComponent,
    FooterComponent
  }
}
</script>
<style>
.my-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
}
main {
  flex: 2;
}
.link {
  padding-top: 20px;
}
a {
  text-decoration: underline;
}
</style>
