<template>
  <div class="main_section">
    <header v-if="currentStep < 4" class="border-b border-[#F4F4F4] px-[20px] py-[8px] text-center">
      <!-- <img class="w-[85px] h-[33px] inline-block" src="../../../public/images/geeq_logo_mobile.svg" /> -->
    </header>
    <slot />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'HomeMobileLayout',
  computed: {
    ...mapState(['currentStep']),
  },
};
</script>

<style scoped>
/* Your CSS styles here */
.main_section {
    /* background-image: url('../../../public/images/Snow_bg_mobile.svg');
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: auto; */
}
</style>
