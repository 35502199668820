import axios from 'axios';

// create an axios instance with default options
const http = axios.create({ baseURL: window.location.origin });
// const http = axios.create({ baseURL: 'https://artist-blkexp.geeq-test.io' });
// const http = axios.create({ baseURL: 'https://s2-vipstack.geeqsearch.io' });
// const http = axios.create({ baseURL: 'https://blkexp.geeq-stage.io' });
// const http = axios.create({ baseURL: 'https://hydrogen.geeqsearch.io' });

// const http = axios.create({
//   baseURL: 'https://6d13-37-252-84-150.ngrok-free.app',
//   headers: {
//     'ngrok-skip-browser-warning': 'true'
//   }
// });


export default {
  searchByHashOrMeta(type: string, searchStr: string, limit: number, skip: number) {
    let searchParam = searchStr;
    if (type !== "hash") {
      searchParam = encodeURIComponent(searchStr);
    }
    return http.get(`/api/blocks?type=${type}&search=${searchParam}&limit=${limit}&skip=${skip}`)
  },
  searchByRefhash(searchStr: string) {
    let searchParam = searchStr;
    searchParam = encodeURIComponent(searchStr);

    return http.get(`/api/blocks/ref-hash?refHash=${searchParam}`)
  },
  searchByMetadata(type: string, searchStr: string, limit: number, skip: number) {
    let searchParam = searchStr;
    searchParam = encodeURIComponent(searchStr);

    return http.get(`/api/blocks/meta-search?searchType=${type}&search=${searchParam}&limit=${limit}&skip=${skip}`)
  },
  searchNftByMetadata(type: string, searchStr: string, limit: number, skip: number) {
    let searchParam = searchStr;
    searchParam = encodeURIComponent(searchStr);

    return http.get(`/api/blocks/meta-search-nft?searchType=${type}&search=${searchParam}&limit=${limit}&skip=${skip}`)
  },
  searchByBlockNumbers(numbers: Array<number>) {
    return http.get(`/api/blocks/byNumber`, {
      params:{
        numbers: numbers,
      }
    })
  },
  searchByBlockNumber(number: number, limit: number, skip: number) {
    return http.get(`/api/blocks/${number}?limit=${limit}&skip=${skip}`)
  },
  getDocument(id: number) {
      return http.get(`/api/document/${id}`)
  },
  getBlockByNumber(number: number) {
    return http.get(`/api/blocks/${number}`)
  },
  getAllTransactionsForAccount(accountNumVal: string, limit: number, skip: number) {
    return http.get(`/api/transactions/transactions?accountNumVal=${accountNumVal}&limit=${limit}&skip=${skip}`)
  },
  getAccountNftTransactions(accountNumVal: string, limit: number, skip: number) {
    return http.get(`/api/transactions/nft-tab?accountNumVal=${accountNumVal}&limit=${limit}&skip=${skip}`)
  },
  getAccountNfts(accountNumVal: string) {
    return http.get(`/api/nftMints/nft-tab?accountNumVal=${accountNumVal}`)
  },
  getTransactionDetailsByHash(hash: string) {
    return http.get(`/api/transactions?hash=${hash}`)
  },
  getTransactionDetailsByNotaryId(notaryPayloadId: number) {
    return http.get(`/api/transactions?notaryPayloadId=${notaryPayloadId}`)
  },
  getTransactionByStackId(id: number) {
    return http.get(`/api/stacks/${id}`)
  },
  getStackTransactionsById(stackId: number, limit: number, skip: number) {
    return http.get(`/api/transactions?stackId=${stackId}&limit=${limit}&skip=${skip}`)
  },
  getTransactionDetailsByRefHash(txRefHash: string) {
    return http.get(`/api/transactions/ref-hash-transaction?txRefhash=${txRefHash}`)
  },
  getTransactionDetailsByRefHashNew(txRefHash: string) {
    return http.get(`/api/blocks/ref-hash?refHash=${txRefHash}`)
  },
  getCsv(from: number, to: number) {
    return http.get(`/api/export/transaction?from=${from}&to=${to}`)
  },
  searchByStackId(stackId: number) {
    return http.get(`/api/stacks/${stackId}`)
  },
  getCoinAccountDetails(address: string) {
    return http.get(`/api/coinAccount?acctNumVal=${address}`)
  },
  getCoinAccountTransactions(address: string, limit: number, skip: number) {
    return http.get(`/api/transactions?acctNumVal=${address}&limit=${limit}&skip=${skip}`)
  },
  getNftById(id: string) {
    return http.get(`/api/nftMints/${id}`)
  }
};
