<template>
  <header class="bg-white header min-h-[222px]">
    <nav class="max-w-[1650px] mx-auto pt-[75px] pb-[69px] px-[20px]">
      <div class="w-full pb-1 mx-auto">
        <div class="nav__container flex items-center justify-between">
          <!-- Website Logo -->
          <router-link to="/" class="flex items-center router-link-active">
            <img src="/images/geeq-logo.png" alt="Logo">
          </router-link>

          <search-form />
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import SearchForm from '@/components/common/SearchForm';

export default {
  name: 'HeaderComponent',
  components: { SearchForm },
  data: () => ({
    //
  }),
  methods: {
    //
  }
}
</script>

<style>
.header .search-form .selector {
  border: 2px solid #F6F6F6 !important;
  padding: 13px 18px;
}
.header .search-form .search-input {
  background: #F6F6F6 !important;
  margin-left: 0;
}
.header .search-form ul {
  border: 1px solid #F6F6F6 !important;
}
.header .search-form .checkcontainer {
  padding-left: 25px;
}
.nav-grid-cont {
  grid-template-columns: 50% 50% !important;
}
@media (max-width: 1160px) {
  .nav__container {
    flex-wrap: wrap;
    justify-content: center !important;
    gap: 20px;
  }
  nav {
    padding-top: 30px !important;
  }
}
</style>
