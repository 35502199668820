import moment from 'moment';

const urlBase64Decode = (str: string) => {
  let output = str.replace('-', '+').replace('_', '/');
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += '==';
      break;
    case 3:
      output += '=';
      break;
    default:
      throw new Error('Illegal base64url string!');
  }
  return window.atob(output);
};

const getClaimsFromToken = (token: any) => {
  let claims = {};
  if (
    token === undefined || token === 'undefined'
    || token === null
    || token === 'null'
    || token === ''
  ) {
    return null;
  }
  const encoded = token.split('.')[1];
  if (!encoded) {
    return null;
  }
  try {
    claims = JSON.parse(urlBase64Decode(encoded));
  } catch (e) {
    return null;
  }
  return claims;
};

const isTokenExpired = (expTime: number, expDiff = 20) => {
  const nowTimestamp = moment().utc().unix();
  const diff = expTime - nowTimestamp;
  if (diff <= expDiff) {
    return true;
  }
  return false;
};

// eslint-disable-next-line no-irregular-whitespace
​export { getClaimsFromToken, isTokenExpired };
