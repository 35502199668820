/* eslint-disable  @typescript-eslint/no-explicit-any */

// Function to get data from Session Storage by given key
export function getFromStorage(key: string) {
  return sessionStorage.getItem(key);
}

// Function to set data to Session Storage by given key, value
export function setToStorage(key: string, value: any) {
  sessionStorage.setItem(key, value);
}

// Function to delete data from Session Storage by given key
export function  deleteFromStorage(key: string) {
  sessionStorage.removeItem(key);
}
