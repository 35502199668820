import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Wrapper from '@/views/Layouts/LayoutWrapper.vue';
import { isMobileScreen } from '@/helpers/common';
import store from '@/store';

function load(path: string) {
  // '@' is aliased to src/components
  return () => import(`@/views/${path}.vue`)
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Wrapper,
    children: [
      {
        path: '/',
        name: 'home',
        component: load('HomeComponent'),
        meta: {
          protected: true,
          layout: 'home'
        }
      },
      {
        path: 'search-result',
        name: 'search-result',
        component: load('SearchResult'),
        meta: {
          protected: false,
          layout: 'main'
        }
      },
      {
        path: 'nft-transactions/:nftId?',
        name: 'nft-transactions',
        component: load('NftTransactionExplorer'),
        meta: {
          protected: false,
          layout: 'main'
        }
      },
       {
        path: 'nfts/:id?',
        name: 'nfts',
        component: load('NftExplorer'),
        meta: {
          protected: false,
          layout: 'main'
        }
      },
      {
        path: 'stacks/:stackId',
        name: 'stacks',
        component: load('StackExplorer'),
        meta: {
          protected: false,
          layout: 'main'
        }
      },
      {
        path: 'accounts/:address',
        name: 'accounts',
        component: load('CoinAccountExplorer'),
        meta: {
          protected: false,
          layout: 'main'
        }
      },
      {
        path: 'transactions',
        name: 'transactions',
        component: load('StackTxExplorer'),
        meta: {
          protected: false,
          layout: 'main'
        }
      },
      {
        path: 'transaction/:notaryId?',
        name: 'transaction',
        component: load('TransactionExplorer'),
        meta: {
          protected: false,
          layout: 'main'
        }
      },
      {
        path: 'ref-hash-transaction/:txRefHash?',
        name: 'ref-hash-transaction',
        component: load('TransactionExplorer'),
        meta: {
          protected: false,
          layout: 'main'
        }
      },
      {
        path: 'block/:number?',
        name: 'block',
        component: load('BlockExplorer'),
        meta: {
          protected: false,
          layout: 'main'
        }
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'page-not-found',
        component: load('PageNotFound'),
        meta: {
          notFound: true,
          protected: false,
          layout: 'home'
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
