<template>
  <!-- <HeaderComponent /> -->
  <router-view :key="$route.fullPath" />
  <!-- <FooterComponent /> -->
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';

export default {
  name: 'App',
  components: {
    // HeaderComponent,
    // FooterComponent,
  },
  async created() {
    // await this.$store.dispatch('getXRedirectionUrl');
    // await this.$store.dispatch('getBlockExplorerHost');
  },
};
</script>

<style>
#app {
  min-height: 100vh;
  background: #F5F8FF;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #3D94C3;
}
</style>
