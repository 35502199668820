<template>
  <footer class="bg-gray-light">
    <div>
      <img src="/images/border-footer.svg" class="w-full" />
    </div>

    <div class="flex justify-around border-t-4 border-white py-[45px] max-w-[1650px]">
        <div
        class="md:text-left text-center mx-1.5 my-1"
      >
        <a
          href="https://geeq.io/"
          target="_blank"
          class="text-[16px] leading-[23px] font-jost-regular text-black"
        >
          © Geeq.io 2024
        </a>
      </div>
      <div class="text-center mx-1.5 my-1">
        <a
          href="https://geeq.io/privacy-policy/"
          target="_blank"
          class="text-[16px] leading-[23px] font-jost-regular text-blue-light"
        >
          Privacy Policy
        </a>
      </div>
      <div class="text-center mx-1.5 my-1">
        <a
          href="https://geeq.atlassian.net/servicedesk/customer/portal/1"
          target="_blank"
          class="text-[16px] leading-[23px] font-jost-regular text-red"
        >
          Service Desk
        </a>
      </div>

      <div
        class="md:text-right text-center mx-1.5 my-1"
      >
        <span class="text-[16px] leading-[23px] font-jost-regular text-black text-right">Geeq Block Explorer v{{ appVersion }}</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
    }
  }
}
</script>
