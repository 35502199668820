import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import '@/assets/styles/tailwind.css';
import '@/assets/styles/index.scss';
import '@/assets/styles/style.css'
import '@/assets/styles/style.scss'
import { init } from './helpers/init';

init(store, router);
createApp(App).use(store).use(router).use(VueToast).mount('#app');
